import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { YOUTTUBE_AUDIO_URL } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE_SPARSH } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, getSortState } from 'react-jhipster';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row, Table } from 'reactstrap';
import { getEntity, reset, updateStatusForTrack,updateTrackSubscriptionStatus, uploadThumbnail,getAlbumDetailsById,deleteEntityTrack } from './admin-sparsh-reducer';
import Satshrut from '../../../content/images/satshrut_default_thumbnail.jpg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMusic } from '@fortawesome/free-solid-svg-icons';

library.add(faMusic);
// import {Satsh} from 'src/main/webapp/content/images/satshrut1'


export interface IAdminSparshManagementProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));
enum Tablist {
  album = 'track',
}

const AdminSparshManagement = (props: IAdminSparshManagementProps) => {
  const location = useLocation();
  const classes = useStyles();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE_SPARSH), location.search)
  );
  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.album);
  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchKey') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchKey')
  );
  const [newSearch, setNewSearch] = useState(searchText);
  const [show, setShow] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [albumDetails, setAlbumDetails] = useState(null);
  const { entity, entities, match, loading, totalItems, isEntityLoading, data, totalElements } = props;

  const [showModal, setShowModal] = useState(false);
  const [showModalSubscriptionStatus, setShowModalSubscriptionStatus] = useState(false);

  const [formValues, setFormValues] = useState(null);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);



  const openModalSubscriptionStatus = () => setShowModalSubscriptionStatus(true);
  const closeModalSubscriptionStatus = () => setShowModalSubscriptionStatus(false);



  const [status, setStatus] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  
  const [trackId, setTrackId] = useState([]);
  const [trackIdSubscriptionStatus, setTrackIdSubscriptionStatus] = useState([]);


  const [isActive, setIsActive] = useState([]);
  const [isActiveSubscriptionStatus, setIsActiveSubscriptionStatus] = useState([]);

  useEffect(() => {
    if (props.entity?.content?.length > 0) { 
      const album = props.entity.content[0]?.audioAlbum;
      setAlbumDetails(album);
    }
  }, [props.entity]);


  useEffect(() => {
    if (props.entity?.content?.length > 0) { 
      const album = props.entity.content[0]?.audioAlbum;
      setAlbumDetails(album);
    }
    else{
      if(props?.entities?.content?.length > 0){
      const album = props?.entities?.content.find(
      (item) => item?.id == props.match.params.id
    );
    setAlbumDetails(album);
    }
  }
  }, [props.entities]);


  useEffect(() => {
    const fetchAlbumDetailsById = async () => {
      try {
        const data = await props.getAlbumDetailsById(props.match.params.id);
        setAlbumDetails(data?.value?.data);
      } catch (error) {
        console.error('Error fetching track details:', error);
      }
    };
  
    fetchAlbumDetailsById(); 
  
  }, [props.match.params.id]);
  

  const handleChange = (checked, id, status) => {
    openModal();
    setStatus(checked);
    setTrackId(id);
    setIsActive(status);
  };


  const handleChangeSubscriptionStatus = (checked, id, status) => {
    openModalSubscriptionStatus();
    setSubscriptionStatus(checked);
    setTrackIdSubscriptionStatus(id);
    setIsActiveSubscriptionStatus(status);
  };


  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'desc' ? 'asc' : 'desc',
      sort: p,
    });
  };

  const isPaginationCorrect = (sortColumn): Boolean => {
    switch (activeTab) {
      case 'track':
        return (
          sortColumn === 'id' ||
          sortColumn === 'track_id' ||
          sortColumn === 'title' ||
          sortColumn === 'play_count' ||
          sortColumn === 'published_Date' ||
          sortColumn === 'duration' ||
          sortColumn === 'like_count'
        );
      default:
        return false;
    }
  };

  const getAllEntities = () => {
    props.getEntity(
      props.match.params.id,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'},${paginationState.order}`,
      activeTab,
      searchText || null
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?searchKey=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
    }
  };

  useEffect(() => {
    
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 500);
    setCurrentTimeout(timeout);
    const endURL = `?searchKey=${searchText}&page=${paginationState.activePage}&sort=${
      isPaginationCorrect(paginationState.sort) ? paginationState.sort : 'id'
    },${paginationState.order}`;
    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab });
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab, searchText]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSearchButton = () => {
    setSearchText(newSearch);
  };
  const history = useHistory();

  const uploadThumbnail = (albumId) => {
    history.push(`${match.url}/uploadThumbnail/${albumId}`);
  };


  const addTracks = (albumId) => {
    history.push(`${match.url}/uploadTrackForAlbum/${albumId}`);
  };

  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab !== newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };

  const handleGoBack = () => {
    const state = { showTab: 'album' };
    props.reset();
    props.history.push(`/sparsh/edit`, state);
  };

  function formatDuration(seconds) {
    if (!seconds) return '--';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const onAccept = async (event, values) => {
    values = formValues;
    var result;
    result = await props.updateStatusForTrack(trackId,!status);
    if (result) {
      closeModal();
      sortEntities();
    }
  };

  const onAcceptSubscriptionStatus = async (event, values) => {
    values = formValues;
    var result;
    result = await props.updateTrackSubscriptionStatus(trackIdSubscriptionStatus,subscriptionStatus);
    if (result) {
      closeModalSubscriptionStatus();
      sortEntities();
    }
  };





  const getLowestTrackId = (trackIds) => {
    if (!trackIds || trackIds.length === 0) {
      throw new Error("Array is empty or undefined");
    }
    return (Math.min(...trackIds) + 1);
  };



  const [deletePopShow, setDeletePopShow] = useState(false)
  const [trackIdforDelete, setTrackIdforDelete] = useState(null)


  function deleteBtn(id: any) {
    setDeletePopShow(true)
    setTrackIdforDelete(id)
  }


  const handleClose = () => {
    setDeletePopShow(!deletePopShow)
  };

  const confirmDelete = async () => {
    const result = await props.deleteEntityTrack(trackIdforDelete);
    setDeletePopShow(false)
    setTrackIdforDelete(null)
    if (result?.value.status == 200) {
        getAllEntities()
    }
  };



  // useEffect(() => {
  //   const album =  props?.entities['content']?.find(item => item?.id == props?.match?.params?.id);
  //   setAlbumDetails(album);
  // }, [props.entities]); 



  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <Row className="justify-content-center">
          <Col md="12">
            <div className="detail-page-heading">
              <div className="detail-back-btn">
                <NavLink onClick={handleGoBack}>
                  <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
                </NavLink>
                <h2 style={{ color: 'rgb(114 108 108)' }}>Manage Sparsh Track</h2>
              </div>
            </div>

            <div className="row main-container">
              <div className="col-md-4 bottom-border">
                <div className="d-flex">
                  <p id="videoUrl" className="detail-lable-txt">
                    Thumbnail
                  </p>
                  <p className="detail-txt">
                  {albumDetails?.thumbnailUrl ? (
                    <img src={albumDetails.thumbnailUrl} style={{ maxWidth: 70, maxHeight: 50 }} alt="Thumbnail" />
                  ) : (
                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                  )}
                  </p>
                </div>
                <div className="d-flex">
                  <p id="videoUrl" className="detail-lable-txt">
                    Album No
                  </p>
                  <p className="detail-txt">{albumDetails?.id}</p>
                </div>
              </div>

              <div className="col-md-4 bottom-border">
                <div className="d-flex">
                  <p id="videoUrl" className="detail-lable-txt">
                    Album Name
                  </p>
                  <p className="detail-txt">{albumDetails?.title}</p>
                </div>
                <div className="d-flex">
                  <p id="channelName" className="detail-lable-txt">
                    Total Tracks
                  </p>
                  <p className="detail-txt">{albumDetails?.totalTracks}</p>
                </div>
              </div>

              <div className="col-md-4 bottom-border">
                <div className="d-flex">
                  <p id="videoId" className="detail-lable-txt">
                    Album ID
                  </p>
                  <p className="detail-txt">{albumDetails?.albumId}</p>
                </div>
                <div className="d-flex">
                  <p id="channelName" className="detail-lable-txt">
                    Album Source
                  </p>
                  <p className="detail-txt">{!albumDetails?.isNonYoutube ? 'Youtube Music': 'User-created'}</p>
                </div>
              </div>

              <div className="col-md-8 bottom-border"> {/* Adjusted to take width of 2 columns */}
                <div className="d-flex">
                  <p id="videoId" className="detail-lable-txt">
                    Album URL
                  </p>
                  <p className="detail-txt">
                    {albumDetails?.isNonYoutube ? '--' : `${YOUTTUBE_AUDIO_URL}${albumDetails?.albumId}`}
                  </p>
                </div>
              </div>
            </div>

          </Col>



        </Row>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={e => handleSearch(e)}
                placeholder="Search by Track"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>




      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Confirm Status change</ModalHeader>
        <ModalBody>Are you sure you want to change this status to {isActive}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="confirm-renewal"  color="success" onClick={onAccept}>
            <FontAwesomeIcon icon="check" />
            &nbsp; Accept
          </Button>
        </ModalFooter>
      </Modal>



      <Modal isOpen={showModalSubscriptionStatus} toggle={closeModalSubscriptionStatus}>
        <ModalHeader toggle={closeModalSubscriptionStatus}>Confirm Status change</ModalHeader>
        <ModalBody>Are you sure you want to change this status to {isActiveSubscriptionStatus}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalSubscriptionStatus}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="confirm-renewal"  color="success" onClick={onAcceptSubscriptionStatus}>
            <FontAwesomeIcon icon="check" />
            &nbsp; Accept
          </Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={deletePopShow} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this Track?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button disabled={loading} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>


      <div className="row">
        <div className="col-md-4" style={{ marginBottom: 30 }}>
          <button
            id="upload-thumbnail"
            type="button"
            className="common-btn"
            onClick={() => uploadThumbnail(albumDetails?.id)}
            style={{
              width: 200,
              marginRight: 20,
            }}
          >
            <span className="header-title">
              {albumDetails?.isNonYoutube ? "Edit Album" : "Upload Thumbnail"}
            </span>

          </button>

          {albumDetails?.isNonYoutube && (
            <button
              id="add-tracks"
              type="button"
              className="common-btn"
              onClick={() => addTracks(albumDetails?.id)}
              style={{
                width: 200,
                marginRight: 30,
              }}
            >
              <span className="header-title">Add Tracks</span>
            </button>
          )}

        </div>
      </div>



    <div className="col-md-12">
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Track" value={Tablist.album} className={`${classes.tab} ${activeTab === Tablist.album ? classes.activeTab : ''}`} />
      </Tabs>
    </div>

      <div className="table-responsive">
        {activeTab === 'track' && show && !isEntityLoading && (
          <>
            {entity && entity['content']?.length > 0 ? (
              <Table responsive style={{ marginTop: 10 }} id="id">
                <thead>
                  <tr>
                    <th className="hand" onClick={sort('id')}>
                    Sr. No
                      {/* id */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand" >
                      Thumbnail
                      {/* thumbnailUrl */}
                      <span style={{ float: 'right' }}>
                        {' '}
                      </span>
                    </th>
                    <th className="hand" onClick={sort('track_id')}>
                      Track ID
                      {/* trackId */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand" onClick={sort('title')}>
                      Track Name
                      {/* title */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                    <th className="hand" onClick={sort('play_count')}>
                      Listen Count
                      {/* playCount */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>


                    {/* likeCount */}

                    
                    {/* <th className="hand" onClick={sort('like_count')}>
                      Likes */}
                      {/* <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th> */}
                    <th className="hand" onClick={sort('published_Date')}>
                      Published Date
                      {/* publishedDate */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand" onClick={sort('duration')}>
                      Duration
                      {/* duration */}
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>

                    <th className="hand">
                      Track Status
                      {/* status */}
                    </th>

                    <th className="hand">
                      Subscription Status
                      {/* status */}
                    </th>

                    <th>
                      {/* status */}
                      Lyrics
                    </th>

                    <th>
                      {/* status */}
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody>
                  {entity['content']?.map((trackData, i) => (
                    <tr key={`entity-${i}`}>
                      

                      {/* id */}
                      <td>{trackData?.id ? i+1 : '--'}</td>

                      {/* Thumbnail */}
                      <td>
                        {trackData && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {trackData.thumbnailUrl ? (
                              <img src={trackData.thumbnailUrl} style={{ maxWidth: 70, maxHeight: 50 }} key={trackData.id}></img>
                            ) : (
                              <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                            )}
                          </div>
                        )}
                      </td>

                      {/* trackId */}
                      <td>{trackData?.trackId ? trackData.trackId : '--'}</td>

                      {/* title */}
                      <td>{trackData?.title ? trackData.title : '--'}</td>

                      {/* playCount */}
                      <td>{trackData?.playCount === 0 ? '--' : trackData?.playCount || '--'} </td>

                      {/* likeCount */}
                      {/* <td>{trackData?.likeCount ? trackData.likeCount : '--'}</td> */}

                      {/* publishedDate */}
                      <td>{trackData?.publishedDate ? trackData.publishedDate?.toString().substring(0, 10) : '--'}</td>

                      {/* Duration */}
                      <td>{trackData?.duration ? formatDuration(trackData.duration) : '--'}</td>

                      {/* Status */}
                      <td>
                        <Switch  
                        checked={trackData?.activated}
                        onChange={(checked) => handleChange(trackData?.activated, trackData?.id , trackData?.activated ? 'inactive' : 'Active')} 
                        />
                        {trackData?.activated ? 'Active' : 'Inactive'}
                      </td>

                      {/* Subscription Status */}
                      <td>
                        <Switch  
                        color="primary"
                        checked={trackData?.subscriptionPlan?.id == 24}
                        onChange={(checked) => handleChangeSubscriptionStatus(trackData?.subscriptionPlan?.id == 24 ? 1 : 24, trackData?.id , trackData?.subscriptionPlan?.id == 24 ? 'free' : 'paid')} 
                        />
                        {trackData?.subscriptionPlan?.id == 24 ? 'Paid' : 'Free'}
                      </td>

                      {/* Actions */}
                      {/* <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink tag={Link} to={`${match.url}/edit`}>
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                        </div>
                      </td> */}

                       {/* Lyrics */}
                        <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink tag={Link} to={`${match.url}/edit/${trackData?.id}`}>
                            <button
                              id="save-entity"
                              type="submit"
                              className={'common-btn'}
                              style={{
                                width: 120,
                              }}
                            >
                              <span className="header-title">Add Lyrics</span>
                            </button>
                          </NavLink>
                        </div>
                      </td>

                       {/* Actions */}
                        <td>
                        <div className="btn-group flex-btn-group-container">
                          <NavLink tag={Link} to={`${match.url}/editTrack/${trackData?.id}`}>
                            {/* <button
                              id="save-entity"
                              type="submit"
                              className={'common-btn'}
                              style={{
                                width: 120,
                              }}
                            >
                              <span className="header-title">Edit</span>
                            </button> */}


                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />



                          </NavLink>


                          <NavLink tag={Link} to={`${match.url}/editTrackAssociation/${trackData?.id}`}>

                              <FontAwesomeIcon
                                icon="eye"
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  color: '#3a9b72',
                                }}
                                data-toggle="tooltip"
                                title="View"
                              />
                      </NavLink>


                          <NavLink tag={Link} to={`${match.url}/uploadTrack/${trackData?.id}`}>
                            <FontAwesomeIcon
                              icon="music"
                              style={{
                                height: '23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Upload Track"
                            />
                          </NavLink>


                          <NavLink
                            onClick={() => deleteBtn(trackData?.id)}
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginBottom: 2,
                                color: '#db261d',
                              }}
                              data-toggle="tooltip"
                              title="Delete"
                            />
                          </NavLink>

                        </div>
                      </td>

                      
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No track found</div>
            )}
          </>
        )}{' '}
        {props.totalItems ? (
          <div className={` asso-table ${entity && entity['content']?.length > 0 && show && !isEntityLoading ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ sparshAlbumManagement }: IRootState) => ({
  data: sparshAlbumManagement,
  entities: sparshAlbumManagement.entities,
  entity: sparshAlbumManagement.entity,
  loading: sparshAlbumManagement.loading,
  totalItems: sparshAlbumManagement.entity.totalElements,
  totalElements: sparshAlbumManagement.totalElements,
  template: sparshAlbumManagement.template,
  isEntityLoading: sparshAlbumManagement.loading,
  loadingFile: sparshAlbumManagement.loadingFile,
});

const mapDispatchToProps = {
  getEntity,
  getAlbumDetailsById,
  reset,
  updateStatusForTrack,
  updateTrackSubscriptionStatus,
  deleteEntityTrack
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshManagement);
