import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import './crowd-sourcing.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink, Row, Table, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity,blockUser,unBlockUser } from './crowd-sourcing.reducer';

export interface ICrowdSourcingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CrowdSourcingDetails = (props: ICrowdSourcingDetailProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const { incidentEntity: incidentEntity, loading, totalItems, updating } = props;
  console.log('adminPlaylistEntity: ', incidentEntity);

  useEffect(() => {
    props.getEntity(props?.match?.params?.id);

    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
  }, [props?.match?.params?.id]);

  const handleBlockingUser = () => {

    props.blockUser(incidentEntity.reportedBy.id,props?.match?.params?.id)
  };
  const handleUnBlockingUser = () => {
   
    props.unBlockUser(incidentEntity.reportedBy.id,props?.match?.params?.id)
  };
  return incidentEntity && incidentEntity?.id ? (
    <div className="row">
      <Col md="12">
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <NavLink tag={Link} to={'/crowd-sourcing' + props.location.search}>
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Id
              </p>
              <p className="detail-txt">{incidentEntity?.id}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Type
              </p>
              <p className="detail-txt">{incidentEntity?.incidentType}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Description
              </p>
              <p className="detail-txt">{incidentEntity?.incidentWord}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Timeframe
              </p>
              <p className="detail-txt">{incidentEntity?.timeframe}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Created By
              </p>
              <p className="detail-txt">{incidentEntity?.createdBy}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Created Date
              </p>
              <p className="detail-txt">{incidentEntity?.createdDate.split('T')[0]}</p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className="detail-lable-txt">
                Status
              </p>
              <p className="detail-txt">{incidentEntity.status ? incidentEntity?.status : '--'}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Video Title
              </p>
              <p className="detail-txt">{incidentEntity?.videoTitle}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Video Id
              </p>
              <p className="detail-txt">{incidentEntity?.contentId}</p>
            </div>
          </div>
        </div>
        <button
          disabled={updating}
          id="save-entity"
          type="button"
          onClick={!incidentEntity.reportedBy.incidentComments?handleUnBlockingUser:handleBlockingUser}
          className="common-btn"
          style={{
            width: 'fit-content',
            marginTop: 20,
            padding: 5,
          }}
        >
          <span className="header-title">{!incidentEntity.reportedBy.incidentComments?"Unblock User ":"Block User From Commenting"}</span>
        </button>
      </Col>
    </div>
  ) : null;
};

const mapStateToProps = ({ crowdSourcingState }: IRootState) => ({
  incidentEntity: crowdSourcingState.entity,
  totalItems: crowdSourcingState.totalItems,
  loading: crowdSourcingState.loading,
  updating: crowdSourcingState.updating,
});

const mapDispatchToProps = { getEntity,blockUser,unBlockUser };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CrowdSourcingDetails);
