import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Button, NavLink, Row, Table } from 'reactstrap';
import { NavLink as Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { Switch } from '@material-ui/core';


import {  TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import './video-meta-data.scss';



import { IRootState } from 'app/shared/reducers';
import {
  getEntities,
  getTemplate,
  uploadCSVFile,
  searchVideoList,
  syncYoutubVideo,
  videoDownload,
  deleteEntity,
  updateStatusForVideo,
  activateAllAudio
  // downloadVideoMetaData,
} from './video-meta-data.reducer';

import {  APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

import { useGoogleLogout, useGoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import axios from "axios";
export interface IVideoMetaDataProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }
// const clientId = "130831750859-lbibg8vm8qg1dld5f78ml036inedcgim.apps.googleusercontent.com"
const clientId = '795835921840-0uc8gamhqs3r888udn0p1sbuli98vjt6.apps.googleusercontent.com';
export const VideoMetaData = (props: IVideoMetaDataProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [searchText, setSearchText] = useState( new URLSearchParams(props.location.search).get('searchValue')=='null'?null:new URLSearchParams(props.location.search).get('searchValue'));
  const [newSearch,setNewSearch]= useState( searchText);
  const [google, setGoogle] = useState(null);

  const [loadingBtn, setLoadingBtn] = useState(false)
  const[show,setShow]=useState(false)
  const [currentTimeOut,setCurrentTimeOut]=useState(null)
  const history = useHistory();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
     
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);


  const onSuccess = async res => {
    const { code } = res;

    signOut();
 
  };

  const onFailure = res => { };

  const onLogoutSuccess = res => { };


  var scope = 'profile email https://www.googleapis.com/auth/youtube';
  const { signIn } = useGoogleLogin({
    onSuccess: (response) => onSuccess(response),
    onFailure: (error) => {
      if (error.error === 'idpiframe_initialization_failed') {
        console.log('Access token expired');
      } else {
        console.log('Login failure:', error);
      }
    },
    clientId,
    scope: "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtubepartner openid https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.force-ssl",
    isSignedIn: true,
    responseType: 'code'
  });

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  });


  const handleLogin = () => {
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/youtube.channel-memberships.creator%20https://www.googleapis.com/auth/youtube%20https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/youtube.upload%20https://www.googleapis.com/auth/youtube.force-ssl%20https://www.googleapis.com/auth/youtubepartner&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http://localhost:9000/video-meta-data?page=1&sort=id,asc&client_id=795835921840-0uc8gamhqs3r888udn0p1sbuli98vjt6.apps.googleusercontent.com';
  };



  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };
  const getAllEntities = () => {
    const params = {
      "searchValue": searchText||null
    }
    
      props.getEntities(
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        `${paginationState.sort === 'id' ? 'serialNumber' : paginationState.sort}`,`${paginationState.order}`,params
      );
  
   
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?searchValue=${searchText}&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;

    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
    currentTimeOut&&clearTimeout(currentTimeOut)
    setCurrentTimeOut(null)
    const timeout=setTimeout(()=>{
      setShow(true)
    },100)
    setCurrentTimeOut(timeout)
  }, [paginationState.activePage, paginationState.order, paginationState.sort,searchText]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  }






  const handleSearchButton = () => {
  
    setSearchText(newSearch)

  };

  const handleSearch = (e) => {
    const { value } = e.target;
   if(value){
    setNewSearch(value);
   }
   else{
    setNewSearch(null);
    setSearchText(null)
   
    
   }
  };
  const { videoMetaDataList, match, loading, totalItems, updating } = props;
  const exportToCSV = () => {
    getTemplate();
  };

  const isPreview = false;

  let uploadSingleFile = e => {
    if (e?.target?.files[0]) {
      const formData = new FormData();
      formData.append('file', e?.target?.files[0]);
      const res = props.uploadCSVFile(formData,
        paginationState.activePage - 1,
        paginationState.itemsPerPage,
        `${paginationState.sort === 'id' ? 'serialNumber' : paginationState.sort}`,`${paginationState.order}`
      );
     
    }
  };

  const FormUpload = () => {
    return (
      <div className="form-upload-container float-right common-btn">
        <div className="input-field-container " style={{ width: 200, justifyContent: 'center', alignItems: 'center' }}>
          {!isPreview && !props.loadingFile && <input accept=".xlsx,.csv" type="file" onChange={uploadSingleFile} />}
          {!isPreview && (
            <div className="customized-fileupload" style={{ paddingTop: props.loadingFile ? 18 : null }}>
              <span className="header-title">{props.loadingFile ? 'Processing' : 'Upload MetaData'}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const downloadVideoMetaData = () => {
    setLoadingBtn(true)
    const requestUrl = `/api/video-meta-data/exportToExcel`;
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    fetch(requestUrl, { headers })
      .then(response => response.blob())
      .then(blobby => {
        setLoadingBtn(false)
        if (blobby.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = 'VideoMetaDataFormat.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoadingBtn(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const googleLoginFun = async () => {
    setGoogle(true)
    // signIn()
    window.gapi.auth2.getAuthInstance().grantOfflineAccess({
      scope: "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtubepartner openid https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.force-ssl",
      accessType: 'offline',
      prompt: 'consent'
    })
      .then(async (response) => {
        const authCode = response.code;
        if (authCode) {
          const tokens = {
            authorizationCode: authCode,
          };
          const result = await props.syncYoutubVideo(tokens,
            paginationState.activePage - 1,
            paginationState.itemsPerPage,
            `${paginationState.sort === 'id' ? 'serialNumber' : paginationState.sort}`,`${paginationState.order}`
          );
          if (result) {
            sortEntities()
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('Login failure:', error);
      });
  }

  const capitalizeFirstLetter = (str) => {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  };


  const [deletePopShow, setDeletePopShow] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const deleteBtn = (id) => {
    setDeletePopShow(true)
    setVideoId(id)
  }

  const handleClose = () => {
    setDeletePopShow(!deletePopShow)
  };

  const confirmDelete = async () => {
    const result = await props.deleteEntity(videoId);
    setDeletePopShow(false)
    setVideoId(null)
    if (result?.value.status == 204) {
 
        getAllEntities()
   
    }
  };

  const [status, setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalToActivateAudio, setShowModalToActivateAudio] = useState(false);
  const [vidId, setVidId] = useState([]);
  const [isActive, setIsActive] = useState([]);




  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const openModalToActivateAudio = () => setShowModalToActivateAudio(true);
  const closeModalToActivateAudio = () => setShowModalToActivateAudio(false);

  const handleChange = (checked, id, status) => {

    openModal();
    setStatus(checked);
    setVidId(id);
    setIsActive(status);
  };

  const onAccept = async (event, values) => {
    var result;    
    result = await props.updateStatusForVideo(vidId,!status);
    if (result) {
      closeModal();
      sortEntities();
    } 
  };


  const onAcceptActiveAllAudios = async (event, values) => {
    var result;    

    result = await props.activateAllAudio();
    if (result) {
      closeModalToActivateAudio();
      sortEntities();
    }
  };




  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <h2 id="video-meta-data-heading" style={{ color: 'rgb(114 108 108)' }}>
          Manage Video Meta Data
        </h2>
        <div>
          {FormUpload()}
   


          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>Search</button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink onClick={() => loadingBtn ? null : downloadVideoMetaData()}>
              <span className="header-title">{loadingBtn ? 'Processing...' : 'Download MetaData'}</span>
            </NavLink>
          </div>


          <div className="float-right common-btn left-margin">
            <NavLink onClick={() => props?.youtubVideoSync ? null : googleLoginFun()}>
              <span className="header-title">{props?.youtubVideoSync ? 'Processing...' : 'Video Sync'}</span>
            </NavLink>    
          </div>

          <div className="float-right common-btn left-margin">
            <NavLink onClick={() => openModalToActivateAudio()}>
              <span className="header-title">
                {props?.loading ? 'Processing...' : 'Activate All Audios'}
              </span>
            </NavLink>
          </div>



        </div>
   
      </div>

      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Confirm Status change</ModalHeader>
        <ModalBody>Are you sure you want to change this status to {isActive}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="confirm-renewal"  color="success" onClick={onAccept}>
            &nbsp; Accept
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showModalToActivateAudio} toggle={closeModalToActivateAudio}>
        <ModalHeader toggle={closeModalToActivateAudio}>Confirm Audio Status change</ModalHeader>
        <ModalBody>ACTIVATE all the Audio Associated with VIDEO ?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalToActivateAudio}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="confirm-renewal"  color="success" onClick={onAcceptActiveAllAudios}>
            &nbsp; Accept
          </Button>
        </ModalFooter>
      </Modal>


      {show&& !loading &&<div className="table-responsive">
        {videoMetaDataList &&  videoMetaDataList.length > 0 ? (
          <Table responsive style={{ marginTop: 10 }} id='userTableId'>
            <thead>
              <tr>
                <th className="hand" onClick={sort('serialNumber')}>
                  Sr No
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
                <th className="hand" onClick={sort('videoUrl')}>
                  Video Url
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
                <th className="hand" onClick={sort('videoTitle')}>
                  Video Title
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
  
                <th className="hand" onClick={sort('language')}>
                  Language
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>

           
                <th className="hand" onClick={sort('subscriptionName')}>
                  Sub Plan
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
               
                <th className="hand" onClick={sort('videoRecordDate')}>
                  Record Date
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
                <th className="hand" onClick={sort('updatedDate')}>
                  Last Updated Date
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>
                <th className="hand" onClick={sort('statuses.statusValue')}>
                  Video Status
                  <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                </th>

                <th 
                // className="hand" 
                // onClick={sort('isAudioActivated')}
                >
                Associated Audio Status
                {/* <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span> */}
                </th>
             
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {videoMetaDataList?.map((videoMetaData, i) => (
                <tr key={`entity-${i}`}>
                  <td>{videoMetaData.serialNumber ? videoMetaData.serialNumber : '--'}</td>
                  <td style={{ wordBreak: 'break-word' }}>
                    <p className="textwrapafter3line">{videoMetaData.videoUrl ? videoMetaData.videoUrl : '--'}</p>
                
                  </td>
                  <td>
                    {videoMetaData?.videoTitle ? videoMetaData.videoTitle : '--'}
                  </td>
  
                  <td>{videoMetaData?.language ? capitalizeFirstLetter(videoMetaData.language) : '--'}</td>
                
                  <td>{videoMetaData?.subscriptionName ? videoMetaData?.subscriptionName : '--'}</td>
                  <td>{videoMetaData?.videoRecordDate ? videoMetaData.videoRecordDate : '--'}</td>
            
                  <td className="date-font">
                    {videoMetaData?.updatedDate ? (
                      <TextFormat type="date" value={videoMetaData?.updatedDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td>{videoMetaData?.statuses?.statusValue? videoMetaData.statuses.statusValue : '--'}</td>
                  


                  <td>
                    <Switch  
                      color="primary"
                      checked={videoMetaData?.isAudioActivated}
                      onChange={(checked) => handleChange(videoMetaData?.isAudioActivated, videoMetaData?.id, videoMetaData?.isAudioActivated ? 'inactive' : 'active')} 
                      disabled={!videoMetaData?.audioUrl}
                      className={!videoMetaData?.audioUrl ? 'Mui-disabled' : videoMetaData?.isAudioActivated ? 'active' : 'inactive'}
                    />
                    {!videoMetaData?.audioUrl ? (
                      'Audio Not Available'
                    ) : (
                      videoMetaData?.isAudioActivated ? 'Active' : 'Inactive'
                    )}
                  </td>

               
                  <td>
                    <div className="btn-group flex-btn-group-container">

                      <NavLink
                        tag={Link}
                        to={`${match.url}/${videoMetaData.id}/edit?searchValue=${searchText==''?null:searchText}&page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      >
                        <FontAwesomeIcon
                          icon="pencil-alt"
                          style={{
                            height: ' 23px',
                            width: '18px',
                            marginRight: 2,
                            color: '#275cb0',
                          }}
                          data-toggle="tooltip"
                          title="Edit"
                        />
                      </NavLink>
                      <NavLink
                        onClick={() => deleteBtn(videoMetaData.id)}
                     
                      >
                        <FontAwesomeIcon
                          icon="trash"
                          style={{
                            height: ' 23px',
                            width: '18px',
                            marginBottom: 2,
                            color: '#db261d',
                          }}
                          data-toggle="tooltip"
                          title="Delete"
                        />
                      </NavLink>

               
  
                      <NavLink tag={Link}
                              to={`${match.url}/${searchText==''?null:searchText}/${paginationState.activePage}/${paginationState.sort}/${paginationState.order}/${videoMetaData.id}`}
                            >
                              <FontAwesomeIcon
                                icon="eye"
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  color: '#3a9b72',
                                }}
                                data-toggle="tooltip"
                                title="View"
                              />
                      </NavLink>

                      {/* UPLOAD MUSIC */}
                      <NavLink tag={Link}
                              to={`${match.url}/${searchText==''?null:searchText}/${paginationState.activePage}/${paginationState.sort}/${paginationState.order}/uploadTrack/${videoMetaData.id}`}
                            >
                              <FontAwesomeIcon
                                icon="music"
                                style={{
                                  height: '23px',
                                  width: '18px',
                                  marginRight: 2,
                                  color: '#275cb0',
                                }}
                                data-toggle="tooltip"
                                title="Upload Track"
                                />
                      </NavLink>
          
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Video Meta Data found</div>
        )}
      </div>}{' '}
      {show&& !loading &&props.totalItems ? (
        <div className={videoMetaDataList && videoMetaDataList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}


      <Modal isOpen={deletePopShow} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this VideoMetaData?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

const mapStateToProps = ({ videoMetaData }: IRootState) => ({
  videoMetaDataList: videoMetaData.entities,
  loading: videoMetaData.loading,
  totalItems: videoMetaData.totalItems,
  template: videoMetaData.template,
  uploadCsv: videoMetaData.uploadCsv,
  video: videoMetaData,
  videoList: videoMetaData.videoList,
  loadingFile: videoMetaData.loadingFile,
  youtubVideoSync: videoMetaData.youtubVideoSync,
  updating:videoMetaData.updating
});

const mapDispatchToProps = {
  getEntities,
  // getTemplate,
  uploadCSVFile,
  searchVideoList,
  syncYoutubVideo,
  videoDownload,
  deleteEntity,
  updateStatusForVideo,
  activateAllAudio,

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoMetaData);
