/* eslint-disable no-console */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Row, NavLink, Col, Label } from 'reactstrap';

import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getSubscriptionPlans } from 'app/entities/subscription-plan/subscription-plan.reducer';

import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import {
  getEntity,
  updateVideoPlaylistEntity,
  createVideoPlaylistEntity,
  reset,
  updateSubImage,
  createEbookPlaylistEntity,
  updateEbookPlaylistEntity,
  getLocationList,
} from './admin-playlist.reducer';
import FormUpload from './admin-playlist-thumbnail-upload';
import { SERVER_API_URL } from 'app/config/constants';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSpeakerList } from '../admin-associations-Management/admin-associations-management.reducer';
export interface IAdminPlaylistUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; type: string }> {}

export const AdminPlaylistUpdate = (props: IAdminPlaylistUpdateProps) => {
  const { playlistEntity, statuses, loading, updating } = props;

  const [file, setFile] = useState<any>(null);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(playlistEntity?.thumbnail ? playlistEntity?.thumbnail : false);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [keyword, setKeyword] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState(null);
  const [speakerList, setSpeakerList] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const [description, setDescription] = useState('');
  const [isUpdateSuccessfull, setIsUpdateSuccessfull] = useState<boolean>(false);
  const [isImageError, setImageError] = useState<boolean>(false);
  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };
  const fieldsArray = [
    'playlistName',
    'statuses',
    'description',
    'author',
    'location',
    'startDate',
    'endDate',
    'recommendedBy',
    'writtenBy',
    'writtenTo',
    'thumbnailimg',
    'id',
  ];

  const handleClose = () => {
    const state = { showTab: props.match.params.type };
    props.history.push('/admin-playlist' + props.location.search, state);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id, props.match.params.type);
    }

    props.getStatuses();
  }, []);
  useLayoutEffect(() => {
    const getAvailableLocationList = async () => {
      const data: string[] = await getLocationList();
      // console.log(data);
      setLocationList(
        data.map((location, index) => {
          return { value: index, label: location };
        })
      );
    };

    getAvailableLocationList();
  }, []);
  useLayoutEffect(() => {
    const getAvailableSpeakerList = async () => {
      const data: string[] = await getSpeakerList();
      // console.log(data);
      setSpeakerList(
        data.map((speaker, index) => {
          return { value: index, label: speaker };
        })
      );
    };

    if (props?.match?.params?.type == 'video') {
      getAvailableSpeakerList();
    }
  }, [props?.match?.params?.type]);
  useEffect(() => {
    const speakerFunction = () => {
      if (playlistEntity && playlistEntity.speaker) {
        setSpeaker(speakerList.filter(speaker => speaker.label == playlistEntity.speaker)[0]);
      } else setSpeaker(null);
    };

    if (props?.match?.params?.type == 'video') {
      speakerFunction();
    }
  }, [speakerList, playlistEntity, isNew]);
  useEffect(() => {
    const speakerFunction = () => {
      if (playlistEntity && playlistEntity.location) {
        setLocation(locationList.filter(location => location.label == playlistEntity.location)[0]);
      } else setLocation(null);
    };

    speakerFunction();
  }, [locationList, playlistEntity, isNew]);

  const saveEntity = async (event, errors, values) => {
    values.thumbnail = null;
    let array;
    if (props.match.params.type === 'video') {
      array = {
        ...values,
        statuses: {
          id: values.statusesId,
        },
        nameSearch: values.playlistName,
        location: location?.label || null,
        speaker: speaker?.label || null,
      };
    } else {
      array = {
        ...values,
        statuses: {
          id: values.statusesId,
        },
        nameSearch: values.playlistName,
        location: location?.label || null,
      };
    }

    // console.log(array);

    if (errors.length === 0) {
      const entity = {
        ...playlistEntity,
        ...array,
      };

      const formData1 = new FormData();
      let result;
      if (props.match.params.type === 'video') {
        if (isNew) {
          if (file?.name) {
            formData1.append('file', file);
            formData1.append('playListid', props.match.params.id);

            try {
              result = await props.createVideoPlaylistEntity(entity);
              if (result) {
                setIsUpdateSuccessfull(true);
                await props.updateSubImage(result.id, formData1);
                setImageError(false);
                handleClose();
              }
            } catch (error) {
              // Handle errors here
              console.error(error);
            }
          } else {
            setImageError(true); // No file uploaded
            toast.error('Please upload a thumbnail', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          if (file?.name) {
            formData1.append('file', file);
            formData1.append('playListid', props.match.params.id);

            try {
              result = await props.updateVideoPlaylistEntity(entity);
              if (result) {
                setIsUpdateSuccessfull(true);
                await props.updateSubImage(result.id, formData1);
                setImageError(false);
                handleClose();
              }
            } catch (error) {
              // Handle errors here
              console.error(error);
            }
          } else {
            // No file uploaded during update, just update entity and call handleClose()
            try {
              result = await props.updateVideoPlaylistEntity(entity);
              if (result) {
                setIsUpdateSuccessfull(true);
                handleClose();
              }
            } catch (error) {
              // Handle errors here
              console.error(error);
            }
          }
        }
      } else if (props.match.params.type === 'ebook') {
        try {
          const formData2 = new FormData();
          if (isNew) {
            if (file?.name) {
              formData2.append('thumbnailimg', file);
              Object.keys(entity).forEach(key => {
                if (key === 'file' || key === 'statusesId' || !fieldsArray.includes(key)) return;
                if (key === 'statuses') {
                  entity[key] != null && formData2.append(key, entity[key].id);
                } else {
                  entity[key] != null && formData2.append(key, entity[key]);
                }
              });
              // console.log(formData2, file);
              result = await props.createEbookPlaylistEntity(formData2);
            } else {
              setImageError(true); // No file uploaded
              toast.error('Please upload a thumbnail', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            if (file?.name) {
              formData2.append('thumbnailimg', file);
              Object.keys(entity).forEach(key => {
                if (key === 'file' || key === 'statusesId' || !fieldsArray.includes(key)) return;
                if (key === 'statuses') {
                  entity[key] != null && formData2.append(key, entity[key].id);
                } else {
                  entity[key] != null && formData2.append(key, entity[key]);
                }
              });

              result = await props.updateEbookPlaylistEntity(formData2);
            } else {
              formData2.append('thumbnailimg', null);
              Object.keys(entity).forEach(key => {
                if (key === 'file' || key === 'statusesId' || !fieldsArray.includes(key)) return;
                if (key === 'statuses') {
                  entity[key] != null && formData2.append(key, entity[key].id);
                } else {
                  entity[key] != null && formData2.append(key, entity[key]);
                }
              });
              result = await props.updateEbookPlaylistEntity(formData2);
            }
          }

          if (result) {
            handleClose();
          }
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      }
    }
  };

  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
        setImageError(false);
      };

      fileReader.onerror = error => {
        reject(error);
        setImageError(true);
      };
    });
  };
  const isImageSizeAppropriate = img => {
    let maxWidth;
    let maxHeight;
    if (props.match.params.type === 'ebook') {
      maxWidth = 720;
      maxHeight = 1280;
    } else {
      maxWidth = 1280;
      maxHeight = 720;
    }

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(img);

      image.onload = () => {
        console.log('height: ', image.height, 'width: ', image.width);
        if (image.width == maxWidth && image.height == maxHeight) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      image.onerror = () => {
        reject('Failed to load image.');
      };
    });
  };

  const uploadSingleFile = async e => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      const name = e.target.name;

      try {
        const isSizeAndDimensionsAppropriate = await isImageSizeAppropriate(file);

        if (isSizeAndDimensionsAppropriate) {
          setPreview(true);
          setFile(file);
          convertBase64(file);
        } else {
          setImageError(true);
          if (props.match.params.type === 'ebook') {
            toast.error('Please select an image file with appropriate dimensions (720x1280).');
          } else {
            toast.error('Please select an image file with appropriate dimensions (1280x720).');
          }
        }
      } catch (error) {
        // Handle any errors that occur while loading the image
        console.error(error);
      }
    }
  };
  const handleChangeLocation = (field, value) => {
    switch (field) {
      case 'options':
        setLocation(value);
        break;
      default:
        break;
    }
  };
  const handleChangeSpeaker = (field, value) => {
    switch (field) {
      case 'options':
        setSpeaker(value);
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <NavLink
                // tag={Link} to={'/admin-playlist' + props.location.search}
                onClick={handleClose}
              >
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </NavLink>
              <h2 style={{ color: 'rgb(114 108 108)' }}>
                {isNew ? 'Add' : 'Edit'} {props.match.params.type === 'video' ? 'Playlist' : 'Series'}
              </h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading || isUpdateSuccessfull ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : playlistEntity} onSubmit={saveEntity}>
              <div className="row">
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="playlistName" for="playlistName" className="label-font">
                      {props.match.params.type === 'video' ? 'Playlist Title*' : 'Series Title*'}
                    </Label>
                    <AvField
                      id="playlistName"
                      type="text"
                      name="playlistName"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: ' Title is required.' },
                        minLength: { value: 4, errorMessage: ' Title is required to be at least 4 characters.' },
                        pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                      }}
                    />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label for="statuses" className="label-font">
                      Status*
                    </Label>
                    <AvInput id="statuses" type="select" className="form-control input-font" name="statusesId" required>
                      <option value="" key="0" label="Select" />
                      {statuses
                        ? statuses.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.statusValue}
                            </option>
                          ))
                        : null}
                    </AvInput>
                    <AvFeedback> {props.match.params.type === 'video' ? 'Playlist' : 'Series'} Status is required.</AvFeedback>
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="description" for="video-meta-data-description" className="label-font">
                      {props.match.params.type === 'video' ? 'Playlist Description*' : 'Series Description*'}
                    </Label>
                    <AvField
                      id="video-meta-data-description"
                      type="textarea"
                      cols="45"
                      rows="5"
                      name="description"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: ' Description is required.' },
                        minLength: { value: 4, errorMessage: ' Description is required to be at least 4 characters.' },
                        pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                      }}
                    />
                  </AvGroup>

                  {
                    <div className="image-container">
                      <FormUpload
                        label={file && isNew ? 'Change  Thumbnail' : isNew ? 'Upload  Thumbnail*' : 'Change  Thumbnail'}
                        errorMsg={isImageError}
                        inlineLabel={false}
                        value={null}
                        style={{ height: 150 }}
                        uploadSingleFile={uploadSingleFile}
                      />
                      {fileShow ? (
                        <FormUpload
                          isPreview={isPreview}
                          inlineLabel={false}
                          value={fileShow}
                          style={{ height: 150 }}
                          uploadSingleFile={uploadSingleFile}
                        />
                      ) : (
                        <>
                          {playlistEntity && playlistEntity.id && (
                            <img
                              src={
                                props.match.params.type === 'video'
                                  ? `${SERVER_API_URL}/api/system/playlist/thumbnail/${playlistEntity.id}`
                                  : playlistEntity?.thumbnail
                              }
                              style={{ height: 150, marginTop: 20 }}
                            ></img>
                          )}
                        </>
                      )}
                    </div>
                  }
                  <button
                    disabled={updating}
                    id="save-entity"
                    type="submit"
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 20,
                    }}
                  >
                    <span className="header-title">Save</span>
                  </button>
                </div>
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="startDate" for="startDate" className="label-font">
                      Start Date
                    </Label>
                    <AvField
                      id="startDate"
                      type="date"
                      name="startDate"
                      className="input-font"
                      // validate={{
                      //   required: { value: true, errorMessage: 'Publish Date is required.' },
                      // }}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="endDate" for="endDate" className="label-font">
                      End Date*
                    </Label>
                    <AvField
                      id="endDate"
                      type="date"
                      name="endDate"
                      className="input-font"
                      // validate={{
                      //   required: { value: true, errorMessage: 'Publish Date is required.' },
                      // }}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="location" for="location" className="label-font">
                      Location
                    </Label>
                    {/* <AvField
                      id="location"
                      type="text"
                      name="location"
                      className="input-font"
                     
                    /> */}
                    <CreatableSelect
                      id="location"
                      name="location"
                      options={locationList}
                      value={location}
                      className="input-font"
                      onChange={value => handleChangeLocation('options', value)}
                      // isMulti={false}
                    />
                  </AvGroup>
                  {props.match.params.type == 'video' && (
                    <AvGroup className="input-box-width">
                      <Label id="speaker" for="speaker" className="label-font">
                        Speaker
                      </Label>
                      <CreatableSelect
                        id="speaker"
                        name="speaker"
                        options={speakerList}
                        value={speaker}
                        className="input-font"
                        onChange={value => handleChangeSpeaker('options', value)}
                        // isMulti={false}
                      />
                    </AvGroup>
                  )}
                </div>
                {props.match.params.type === 'ebook' && (
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="author" for="author" className="label-font">
                        Author
                      </Label>
                      <AvField
                        id="author"
                        type="text"
                        name="author"
                        className="input-font"
                        // Add your validation here
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="recommendedBy" for="recommendedBy" className="label-font">
                        Recommended By
                      </Label>
                      <AvField
                        id="recommendedBy"
                        type="text"
                        name="recommendedBy"
                        className="input-font"
                        validate={{
                          minLength: { value: 4, errorMessage: ' "Recommended By" is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="writtenBy" for="writtenBy" className="label-font">
                        Written By
                      </Label>
                      <AvField
                        id="writtenBy"
                        type="text"
                        name="writtenBy"
                        className="input-font"
                        validate={{
                          minLength: { value: 4, errorMessage: ' "Written By" is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                    <AvGroup className="input-box-width">
                      <Label id="writtenTo" for="writtenTo" className="label-font">
                        Written To
                      </Label>
                      <AvField
                        id="writtenTo"
                        type="text"
                        name="writtenTo"
                        className="input-font"
                        validate={{
                          minLength: { value: 4, errorMessage: ' "Written To" is required to be at least 4 characters.' },
                          pattern: { value: '/^(?=.*[a-zA-Z]).+$/', errorMessage: 'Please enter valid input' },
                        }}
                      />
                    </AvGroup>
                  </div>
                )}
              </div>
            </AvForm>
          )}
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  statuses: storeState.statuses.entities,
  playlistEntity: storeState.adminPlaylist.entity,
  loading: storeState.adminPlaylist.loading,
  updating: storeState.adminPlaylist.updating,
  updateSuccess: storeState.adminPlaylist.updateSuccess,
});

const mapDispatchToProps = {
  getSubscriptionPlans,
  getStatuses,
  getEntity,
  updateVideoPlaylistEntity,
  createVideoPlaylistEntity,
  updateSubImage,
  reset,
  createEbookPlaylistEntity,
  updateEbookPlaylistEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminPlaylistUpdate);
