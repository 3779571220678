export interface IUser {
  subscriptionPlanID?: any;
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryCode?: string;
  country?: string;
  state?: string;
  pinCode?: string;
  city?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  subscriptionHistory?: any;
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
  imageUrl?: string;
  startDate?: string;
  endDate?: string;
  incidentComments?: boolean;
  remarks?: string;
  remarksForRenewal?: string;
  subscriptionRenew?: boolean;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  countryCode: '',
  country: '',
  state: '',
  pinCode: '',
  city: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  subscriptionHistory: {},
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  startDate: '',
  endDate: '',
  remarks: '',
  subscriptionPlanID: '',
};
