import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, NavLink, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getSortState, ICrudGetAllAction, JhiPagination, JhiItemCount, TextFormat } from 'react-jhipster';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { getIncidentsList, updateIncidentStatus } from './crowd-sourcing.reducer';

import './crowd-sourcing.scss';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { setTimeout } from 'timers';
import { faCommentDots, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { ICrowdSourcing } from 'app/shared/model/crowd-sourcing.model';

export interface ICrowdSourcingProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

enum Tablist {
  reported = 'reported',
  pending = 'pending',
  approved = 'approved',
}
export const CrowdSourcing = (props: ICrowdSourcingProps) => {
  const [reload, setReload] = useState(false);

  const [searchText, setSearchText] = useState(
    new URLSearchParams(props.location.search).get('searchValue') == 'null'
      ? null
      : new URLSearchParams(props.location.search).get('searchValue')
  );
  const classes = useStyles();
  const [newSearch, setNewSearch] = useState(searchText);
  const [showVideoRemove, setShowVideoRemove] = useState(false);
  const [videoIdToDlt, setVideoIdToDlt] = useState(null);
  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.reported);
  const [show, setShow] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const getAllIntities = () => {
    props.getIncidentsList(pagination.activePage - 1, pagination.itemsPerPage, pagination.sort, pagination.order, searchText);
  };
  useEffect(() => {
    getAllIntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
    const endURL = `?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  }, [pagination.activePage, pagination.sort, pagination.order, searchText]);

  const { crowdSources, match, loading, totalItems, updating } = props;

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const handlePagination = currentPage =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });
  const sort = p => () => {
    setPagination({
      ...pagination,
      order: pagination.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const toggleActive = (incident: ICrowdSourcing, incidetStatus: string) => {
    const params = {
      id: incident.id,
      status: incidetStatus,
    };
    props.updateIncidentStatus(params, pagination.activePage - 1, pagination.sort, pagination.order, searchText, pagination.itemsPerPage);
  };
  const handleSearch = e => {
    const { value } = e.target;
    if (value) {
      setNewSearch(value);
    } else {
      setNewSearch(null);
      setSearchText(null);
    }
  };
  const confirmRemoveVideo = async () => {
    // const result = await props.deleteLiveVid(videoIdToDlt)
    // if(result.value.status==200) {
    //   getAllIntities();
    //   setShowVideoRemove(false);
    //   setVideoIdToDlt(null);
    // }
  };
  const handleCloseRemoveModal = () => {
    setShowVideoRemove(false);
  };
  const removeVideo = id => {
    setShowVideoRemove(true);
    setVideoIdToDlt(id);
  };
  const handleSearchButton = () => {
    setSearchText(newSearch);
  };
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if (activeTab != newValue) {
      setShow(false);
      setActiveTab(newValue);
    }
  };

  return (
    <div className="main-container-list">
      <div style={{ padding: '5px' }} className="page-title-btn-container">
        <h2 id="live-video-heading" style={{ color: 'rgb(114 108 108)' }}>
          Crowd Sources
        </h2>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                value={newSearch}
                onChange={handleSearch}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={handleSearchButton}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink
              tag={Link}
              to={`${match.url}/new?searchValue=${searchText}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
            >
              <span className="header-title">Create New Incident</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {show &&
          (crowdSources && crowdSources.length > 0 ? (
            <Table style={{ marginTop: 10 }} responsive>
              <thead>
                {/* <th>Index</th> */}
                <th onClick={sort('id')}>
                  Incident Id
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('videoId')}>
                  Video Id
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('videoUrl')}>
                  Video Url
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('videoTitle')}>
                  Video Title
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('incidentType')}>
                  Incident
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>{' '}
                <th >
                  Incident Description
             
                </th>{' '}
                <th>
                  Incident Created By
                  
                </th>
                <th onClick={sort('createdDate')}>
                  Date
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th onClick={sort('status')}>
                  Status
                  <span style={{ float: 'right' }}>
                    {' '}
                    <FontAwesomeIcon icon="sort" />
                  </span>
                </th>
                <th>Action</th>
              </thead>
              <tbody>
                {crowdSources.length > 0 ? (
                  crowdSources.map((incidence, i) => (
                    <tr key={`entity-${i}`}>
                      <td>{incidence.id}</td>
                      <td>{incidence.contentId}</td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{incidence.videoUrl ? incidence.videoUrl : '--'}</p>
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{incidence.videoTitle ? incidence.videoTitle : '--'}</p>
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{incidence.incidentType ? incidence.incidentType : '--'}</p>
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{incidence.incidentWord ? incidence.incidentWord : '--'}</p>
                      </td>
                      <td style={{ wordBreak: 'break-word' }}>
                        <p className="textwrapafter2line">{incidence.createdBy ? incidence.createdBy : '--'}</p>
                      </td>

                      <td>{incidence.createdDate.split('T')[0]}</td>

                      <td>{incidence.status} </td>

                      <td className="text-left">
                        <div className="btn-group flex-btn-group-container">
                        <NavLink
                            tag={Link}
                            to={`${match.url}/${incidence.id}/edit?searchValue=${
                              searchText == '' ? null : searchText
                            }&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`}
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              style={{
                                height: ' 23px',
                                width: '18px',
                                marginRight: 2,
                                color: '#275cb0',
                              }}
                              data-toggle="tooltip"
                              title="Edit"
                            />
                          </NavLink>
                          <NavLink tag={Link} to={`${match.url}/${incidence.id}/details?searchValue=${searchText == '' ? null : searchText}&page=${
                              pagination.activePage
                            }&sort=${pagination.sort},${pagination.order}`}>
                            <FontAwesomeIcon
                              icon="eye"
                              style={{
                                height: '25px',
                                width: '25px',
                                color: '#3a9b72',
                              }}
                              data-toggle="tooltip"
                              title="View"
                            />
                          </NavLink>

                          {/* {incidence.status === 'REPORTED' ? (
                            <div className="common-btn" style={{ width: 'fit-content' }}>
                              <NavLink onClick={() => toggleActive(incidence, 'UNDER_REVIEW')}>
                                <span style={{ width: 'fit-content' }} className="header-title">
                                  {' PENDING'}
                                </span>
                              </NavLink>
                            </div>
                          ) : incidence.status === 'UNDER_REVIEW' ? (
                            <div className="common-btn">
                              <NavLink onClick={() => toggleActive(incidence, 'REVIEW_COMPLETED')}>
                                <span className="header-title">{'APPROVE'}</span>
                              </NavLink>
                            </div>
                          ) : (
                            <h2 style={{ fontSize: '20px' }}>Completed</h2>
                          )} */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p>No Data Found</p>
                )}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No incidence found</div>
          ))}
      </div>
      {props.totalItems ? (
        <div className={crowdSources && crowdSources.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            {/* <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled /> */}
            <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
      <Modal isOpen={showVideoRemove} toggle={handleCloseRemoveModal}>
        <ModalHeader toggle={handleCloseRemoveModal}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseRemoveModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button id="jhi-confirm-delete-videoMetaData" disabled={updating} color="danger" onClick={confirmRemoveVideo}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ crowdSourcingState }: IRootState) => ({
  crowdSources: crowdSourcingState.entities,
  loading: crowdSourcingState.loading,
  totalItems: crowdSourcingState.totalItems,
  updating: crowdSourcingState.updating,
});

const mapDispatchToProps = {
  getIncidentsList,
  updateIncidentStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CrowdSourcing);
